import '/assets/styles/partials/empty-message.scss';
import PropTypes from 'prop-types';

// Partials
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

const EmptyMessage = ({ className, children, icon, title }) => (
	<div className={`emptymessage ${className}`}>
		{icon &&
			<Icon className="emptymessage-icon" name={icon} />
		}
		<div className="emptymessage-contentwrap">
			{title &&
				<strong className="emptymessage-title">{title}</strong>
			}
			{children &&
				<div className="emptymessage-content">{children}</div>
			}
		</div>
	</div>
)

EmptyMessage.defaultProps = {
	className: '',
	icon: 'info',
}

EmptyMessage.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	icon: PropTypes.string,
	title: PropTypes.string,
}

export default EmptyMessage