import '/assets/styles/pages/faq.scss';
import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

// Templates
import { SidemenuTemplate } from '/views/layouts/sidemenu'

// Sections
import SectionHeading from '/views/sections/sidemenu/section-heading';
import Section from '/views/sections/sidemenu/section';

// Partials
import Collapser from '/views/partials/collapser';
import Loader from '/views/partials/loader'
import EmptyMessage from '/views/partials/empty-message'

// Context
import { useNavigator, useParams } from '@wearetla/tla-essentials-tools/utilities/navigator'
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { useHead } from '@wearetla/tla-essentials-tools/utilities/head';
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';

// Services
import siteServices from '/services/site';

const getFaqCategories = () => {
	return siteServices.getFaqCategories();
}

const getFaqDetail = (faqId) => {
	return siteServices.getFaqDetail(faqId);
}

const parseHead = (faqData) => {
	return {
		title: (faqData?.title ? faqData.title + ' - ' : '') + 'Sıkça Sorulan Sorular',
		description: 'IF Performance Hall ile ilgili sıkça sorulan sorular',
	}
}

const Faq = ({ pageProps }) => {
	const { redirect } = useNavigator();
	const { desktop, mobile } = useBreakpoints();
	const { setHead } = useHead();
	const { setNotFound, setError } = useGlobalState();
	const { id: faqId } = useParams(['faq']);

	const [faqData, setFaqData] = useState(pageProps?.faqData ?? null);
	const [rawCategories, setCategories] = useState(pageProps?.categories ?? null);

	const categories = useMemo(() => {
		return rawCategories ? rawCategories.map((category) => ({
			href: 'faq',
			active: faqId?.toString() === category?.id?.toString(),
			params: {
				slug: category.slug,
				id: category.id,
			},
			title: category.title,
		})) : [false, false, false]
	}, [rawCategories, faqId]);

	useEffect(() => {
		if(!rawCategories) {
			getFaqCategories().then(setCategories).catch(() => {
				setError();
			});
		}
	}, [rawCategories])

	useEffect(() => {
		if(mobile && rawCategories?.[0] && !faqId) {
			redirect('faq', { slug: rawCategories[0].slug, id: rawCategories[0].id });
		}
	}, [mobile, rawCategories, faqId])


	useEffect(() => {
		if(faqId) {
			if(faqData?.id?.toString?.() !== faqId) {
				setFaqData(null);
				getFaqDetail(faqId).then((data) => {
					setFaqData(data);
				}).catch(() => {
					setNotFound();
				})
			}
		}
		else {
			setFaqData(false);
		}
	}, [faqId, faqData])

	useEffect(() => {
		setHead(parseHead(faqData));
	}, [faqData])

	return (
		<SidemenuTemplate
			title="Sıkça Sorulan Sorular"
			menuItems={categories}>
			<Section className="faq-content">
					<Loader loading={faqData === null && !!faqId} />
					{faqData === false &&
						<EmptyMessage>Bir konu başlığı seçin.</EmptyMessage>
					}
					{!!faqData?.faqs?.length &&
						<>
							{desktop &&
								<SectionHeading title={faqData?.title ?? 'Sıkça Sorulan Sorular'} />
							}
							<ul className="faq-content">
								{faqData.faqs.map((faq, nth) => (
									<li className="content-item" key={nth}>
										<Collapser
											wysiwyg
											className="item-dropdown"
											html={faq.description}
											title={faq.title} />
									</li>
								))}
							</ul>
						</>
					}
			</Section>
		</SidemenuTemplate>
	)
}

Faq.propTypes = {
	pageProps: PropTypes.object
}

Faq.getServerProps = ({ match }) => {
	return new Promise((resolve) => {
		Promise.all([
			getFaqCategories(),
			match.params.id ? getFaqDetail(match.params.id) : (new Promise((resolve) => { resolve(false) })),
		]).then(([
			categories,
			faqData,
		]) => {
			resolve({
				pageProps: {
					categories,
					faqData,
				},
				head: parseHead(faqData),
			})
		}).catch(() => {
			resolve({
			})
		})
	});
}

export default Faq