import classNames from 'classnames'
import PropTypes from 'prop-types';

// Partials
import Loader from '/views/partials/loader';

export const Section = ({ className, children, loading }) => {
	return (
		<div className={classNames('section', 'layout-sidemenu-section', 'loader-container', className)}>
			<Loader solid loading={!!loading} />
			{children}
		</div>
	)
}

Section.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	loading: PropTypes.bool,
}

export default Section;