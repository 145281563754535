import classNames from 'classnames'
import PropTypes from 'prop-types';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

export const SectionHeading = ({ className, title, controls, onGoBack, lined = true }) => {
	return (
		<div className={classNames('layout-sidebar-sectionheading', className, { lined })}>
			<div className="sectionheading-content">
				{onGoBack && <button onClick={onGoBack} type="button" className="sectionheading-backbtn"><Icon name="arrow-left" /></button> }<h1 className="sectionheading-title">{title}</h1>
			</div>
			{controls &&
				<div className="sectionheading-controls">
					{controls}
				</div>
			}
		</div>
	)
}

SectionHeading.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	controls: PropTypes.node,
	onGoBack: PropTypes.func,
	lined: PropTypes.bool,
}

export default SectionHeading;